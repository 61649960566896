import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/home/Index")
    },
    {
      path: "/plants",
      name: "Plants",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/plants/Index")
    },
    {
      path: "/birding",
      name: "Birding",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/birding/Index")
    },
    {
      path: "/about",
      name: "About",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/about/Index")
    },
    {
      path: "/nursery",
      name: "Nursery",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/nursery/Index")
    },
    {
      path: "/retail-nursery",
      name: "Retail Nursery",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/retail-nursery/Index")
    },
    {
      path: "/retail-nursery",
      name: "Retail Nursery",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/retail-nursery/Index")
    },
    {
      path: "/specials",
      name: "Specials",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/Specials/Index")
    },
    {
      path: "/landscaping",
      name: "Landscaping",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/landscaping/Index")
    },
    {
      path: "/walks",
      name: "Walks",
      component: () =>
        import(/* webpackChunkName: "sample" */ "@/views/walks/Index")
    },
    {
      path: "/contact",
      name: "Contact",
      component: () =>
        import(/* webpackChunkName: "blog" */ "@/views/contact/Index")
    },
    {
      path: "/restaurant",
      name: "Restaurant",
      component: () =>
        import(/* webpackChunkName: "blog" */ "@/views/restaurant/Index")
    },
    {
      path: "/consulting",
      name: "Consulting",
      component: () =>
        import(/* webpackChunkName: "blog" */ "@/views/consulting/Index")
    },

    {
      path: "/history",
      name: "History",
      component: () =>
        import(/* webpackChunkName: "blog" */ "@/views/history/Index")
    }
  ]
});

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require("vue-analytics").default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== "development"
    }
  });
}

export default router;
