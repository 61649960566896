<template>
  <v-app>
    <head>
      <title>
        A specialist indigenous nursery located in beautiful Mtunzini. We offer
        landscaping as well as consulting services and have a large amount of
        indigenous trees and shrubs for purchase.
      </title>
    </head>
    <core-toolbar />

    <v-fade-transition mode="out-in">
      <main v-bind="attrs">
        <router-view />
      </main>
    </v-fade-transition>

    <core-footer />
  </v-app>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Twin Streams',
      titleTemplate: 'Twin Streams',
      content:
        'A specialist Indigenous Nursery located in beautiful Mtunzini. We offer landscaping as well as consulting services and have a large amount of indigenous trees and shrubs for purchase.',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    components: {
      CoreFooter: () => import('@/components/core/Footer'),

      CoreToolbar: () => import('@/components/core/Toolbar')
    },

    computed: {
      attrs () {
        return {
          key: this.$route.path,
          style: {
            minHeight: 'calc(100vh - ' + this.$vuetify.application.top + 'px'
          }
        }
      }
    }
  }
</script>

<style>
.v-card {
  border-radius: 0px;
}

.v-application {
  background: #f2f2f2 !important;
  font-family: 'Cormorant Garamond', serif;
}

.v-application .display-3 {
  font-family: 'Cormorant Garamond', serif !important;
}
.v-application .display-1 {
  font-family: 'Cormorant Garamond', serif !important;
}
.v-application .display-2 {
  font-family: 'Cormorant Garamond', serif !important;
}

.v-application .title {
  font-family: 'Cormorant Garamond', serif !important;
}

.v-application .subtitle-1 {
  font-family: 'Cormorant Garamond', serif !important;
}

.v-application .subtitle-2 {
  font-family: 'Cormorant Garamond', serif !important;
}

.v-application .headline {
  font-family: 'Cormorant Garamond', serif !important;
}
.v-overlay {
  z-index: 5;
}

/* .v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    font-family: $title-font, sans-serif !important;
  } */
/* } */
</style>
