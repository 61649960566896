import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify, {
  VRow
} from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  components: {
    VRow
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'fa' // default - only for display purposes <mdi>
  },
  theme: {
    themes: {
      light: {
        primary: '#32CD32',
        secondary: '#424242',
        thirdary: '#D2B48C',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#00000A'

      }
    }
  }
})
