import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import VideoBg from 'vue-videobg'
import vuePlayer from '@algoz098/vue-player'
import VideoBackground from 'vue-responsive-video-background-player'
import VueGtag from 'vue-gtag'
import VueAnalytics from 'vue-analytics'
import Hotjar from 'vue-hotjar'

import {
  VueperSlides,
  VueperSlide
} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faWarehouse,
  faLocationArrow,
  faLandmark,
  faSmile,
  faBrain,
  faBookOpen,
  fas
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

library.add(faWarehouse, fas, faLocationArrow, faLandmark, faSmile, faBrain, faBookOpen)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vueperslides', VueperSlides)
Vue.component('video-background', VideoBackground)
Vue.component('vueperslide', VueperSlide)
Vue.component('video-bg', VideoBg)
Vue.component(vuePlayer)
Vue.use(Hotjar, {
  id: '2257892' // Hotjar Site ID
})
Vue.use(VueRouter)
Vue.use(VueMeta)
 Vue.use(VueGtag, {
   config: { id: 'G-KQ7LXG637M' }
 })
 Vue.use(VueAnalytics, {
  id: 'G-KQ7LXG637M',
  router
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
